<template>
  <div>
    <slot />
    <el-pagination
      background
      layout="prev, pager, next"
      :hide-on-single-page="true"
      :total="totalCount"
      :current-page="value"
      :page-size="perPage"
      @current-change="pageChanged"
    >
    </el-pagination>
  </div>
</template>

<script>
import * as log from "loglevel"

export default {
  props: {
    totalCount: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      default: 20
    },
    value: {
      type: Number,
      required: true
    }
  },
  data() {
    return {}
  },
  async created() {},
  methods: {
    pageChanged(newPage) {
      log.info("pagination: pageChanged")
      this.$emit("input", newPage)
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: newPage
        }
      })
    }
  }
}
</script>
