<template>
  <div>
    <div v-if="loading">
      <slot name="loading">
        <div v-loading="loading" style="height: 64px; margin-top: 32px;"></div>
      </slot>
    </div>
    <slot v-else name="loaded" :data="data" />
  </div>
</template>

<script>
import Repository from "@/repositories/api.js"
import * as log from "loglevel"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  mixins: [ApiErrorHandlerMixin],
  props: {
    endpoint: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      data: null,
      loading: true
    }
  },

  watch: {
    endpoint: function() {
      this.loadData()
    }
  },
  async created() {
    this.loadData()
  },
  methods: {
    async loadData() {
      this.loading = true
      log.info("DataLoader: Loading data…")
      try {
        let response = await Repository.get(this.endpoint, {})
        this.data = response.data
        log.info("DataLoader: Data loaded successfully")
      } catch (error) {
        this.handleApiError(error)
      }

      this.loading = false
    }
  }
}
</script>
