import store from "@/store"
import Repository from "@/repositories/api.js"
const resource = "/admin/articles"

export default {
  async get(articleID) {
    let response = await Repository.get(`${resource}/${articleID}`)
    return response.data.article
  },
  async create(articleData) {
    let response = await Repository.post(`${resource}`, {
      region_id: store.state.auth.chosenRegion,
      article: articleData
    })
    return response.data
  },
  async update(articleID, articleData) {
    let response = await Repository.patch(`${resource}/${articleID}`, {
      article: articleData
    })
    return response.data
  },
  async destroy(articleID) {
    let response = await Repository.delete(`${resource}/${articleID}`)
    return response.data
  },
  async sort(articles, collectionID) {
    let response = await Repository.patch(`${resource}/sort`, {
      articles: articles,
      article_collection_id: collectionID
    })
    return response.data
  },
  async fork(articleID, collectionIds) {
    let response = await Repository.post(`${resource}/${articleID}/fork`, {
      article: {
        collection_ids: collectionIds
      }
    })
    return response.data
  },
  async reviewChanges(articleID) {
    let response = await Repository.get(
      `${resource}/${articleID}/review_changes`
    )
    return response.data
  },
  async updateFromOriginal(articleID) {
    let response = await Repository.post(
      `${resource}/${articleID}/update_from_original`
    )
    return response.data
  }
}
